import React, { useEffect, useState } from "react";
import { FaPhone, FaRecordVinyl, FaTimes } from "react-icons/fa";
import { FaMicrophoneLines,FaMicrophoneLinesSlash } from "react-icons/fa6";
import { MdCallEnd } from "react-icons/md";
import "./MakeCall.scss";
import { useCombinedContext } from "../../context/userContext";
import { getProfileAttributes } from "../../../api/onepgrApi";
import { twilioService } from "../../../services/twilioCallService";
import toasterService from "../../../services/toasterService";
import { useRef } from "react";


const MakeCall = ({ toggleMakeCallModal }) => {
  const [callStarted, setCallStarted] = useState(false);
  const [callDuration, setCallDuration] = useState("00:00:00");
  const [phoneNumber, setPhoneNumber] = useState("");
  const { user } = useCombinedContext();
  const [userDetails, setUserDetails] = useState(null);
  const [fromNumberList, setFromNumberList] = useState([]);
  const [selctedOutboundNumber, setSelctedOutboundNumber] = useState(null);
  const timerInterval = useRef(null);
  const [callIsMuted, setCallIsMuted] = useState(false);
  const callIsMutedRef = useRef(false);
  const [isRecording, setIsRecording] = useState(false);
  const isRecordingRef = useRef(false);
  const recordingSidRef = useRef(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      let userdtls = await getProfileAttributes(user.userId);
      userdtls = JSON.parse(userdtls);
      setUserDetails(userdtls);
      console.log("userDetails---->", userdtls);
      if (twilioService.validateUser(userdtls)) {
        console.log("You are allowed to make call or SMS");
        twilioService.twilioCallSetup();
        if (userdtls.purch_phone_multi) {
          setFromNumberList(userdtls.purch_phone_multi.split(","));
          setSelctedOutboundNumber(userdtls.purch_phone_multi.split(",")[0]);
        }
      } else {
        toasterService.warning("You are not allowed to make call or SMS");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const inviteToConference = () => {
    if (phoneNumber) {
      const number = phoneNumber
        .split("")
        .filter((d) => !isNaN(parseInt(d, 10)))
        .join("");
      startCallTimer();
      twilioService.twilioCall(number, selctedOutboundNumber);
      setCallStarted(true);
    } else {
      toasterService.warning("Please enter the mobile number");
      setCallStarted(false);
    }
  };

  const startCallTimer = () => {
    let totalSeconds = 0;
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }
    timerInterval.current = setInterval(() => {
      totalSeconds++;
      const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, "0");
      const seconds = String(totalSeconds % 60).padStart(2, "0");
      setCallDuration(`${hours}:${minutes}:${seconds}`);
    }, 1000);
  };

  const hangUpCall = async() => {
    if(isRecordingRef.current) {
      await twilioService.updateRecordingStatus(recordingSidRef.current,'stopped');
    }
    twilioService.hangup();
    setCallStarted(false);
    setCallDuration("00:00:00");
    callIsMutedRef.current = false;
    recordingSidRef.current = false;
    setIsRecording(false);
    setCallIsMuted(false)
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
      timerInterval.current = null;
    }
  };

  function toggleCallMute() {
     setCallIsMuted(!callIsMuted);
     callIsMutedRef.current = !callIsMutedRef.current;
     twilioService.twilioCallMute(callIsMutedRef.current);
  }

  async function toggleRecording() {
    setIsRecording(!isRecording);
    isRecordingRef.current = !isRecordingRef.current;
    if (isRecordingRef.current) {
     const resp = await twilioService.startRecording(false);
     if(resp.data&&resp.data.data&&resp.data.data.sid){
      toasterService.success('Recording Started');
       recordingSidRef.current = resp.data.data.sid;
     } else{
      toasterService.error('Something went wrong');
     }
    } else {
     await twilioService.updateRecordingStatus(recordingSidRef.current,'stopped');
     toasterService.success('Recording Stopped');
    }
  }

  return (
    <div className="make-call-overlay">
      <div className="make-call-modal">
        <div className="make-call-header">
          <h2>Make Call</h2>
          <button className="close-btn" onClick={() => toggleMakeCallModal(false)}>
            <FaTimes />
          </button>
        </div>

        <div className="make-call-body">
          <div className="make-call-form-group">
            <label>Select Outbound Caller ID: </label>
            <select
              value={selctedOutboundNumber}
              onChange={(e) => setSelctedOutboundNumber(e.target.value)}
            >
              {fromNumberList.map((fromNumber, index) => (
                <option key={'key'+index} value={fromNumber}>
                  {fromNumber}
                </option>
              ))}
            </select>
          </div>

          <div className="make-call-form-group">
            <label>Call Duration: </label>
            <div className="call-duration-box">{callDuration}</div>
          </div>

          <div className="make-call-form-group">
            <label>Call: </label>
            <input
              className="enterPhone"
              type="text"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <div className="make-call-form-group action-buttons">
            {!callStarted ? (
              <button className="btn btn-primary" onClick={inviteToConference}>
                <FaPhone title="Dial"/>
              </button>
            ) : (
              <>
                <button className={`btn ${isRecording ? "blinking" : ""}`} onClick={toggleRecording}>
                  <FaRecordVinyl title={isRecording ? "Stop Recording" : "Start Recording"}/>
                </button>
                <button className="btn btn-warning" onClick={toggleCallMute}>
                  {
                    callIsMuted ?<FaMicrophoneLinesSlash title="Unmute"/>:<FaMicrophoneLines title="Mute"/>
                  }
                </button>
                <button className="btn btn-danger" onClick={hangUpCall}>
                  <MdCallEnd title="Hang Up"/>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeCall;