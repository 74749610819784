

import React, { useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import EditableMessage from './editableMessage';
import ReviewPrompt from './reviewPrompt';
import PeopleTable from './peopleTable';
import '../styles/animation.css';
import { faPencilAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/messageList.css'
import LinkedInDataTable from './linkedinDataTable';
import CompanyListTable from './CompanyListTable';
import useOnePgrFunctions from './onepgrSaveComponent/useonepgrFunctions';
import OnePgrSaveInterface from './onepgrSaveComponent/onePgrSaveInterface';
import ContentComposer from './ContentComposer';
import SequenceList from './sequenceListTable';
import moment from 'moment';
import SelectListDropTime from './SelectListDropTime';
import EnrichedPeopleTable from './enrichedPeopleTable';
import TextAreaForTesting from './application testing/textArea';
import WebContent from './webContent';
import RunwayVideoCreator from './runwayVideoCreator';
import IpEnrichment from './ipEnrichment';
import LinkedInProfileDisplay from './linkedinProfileDataTable';
import EmployeeTable from './companyEmployeeTable';
import JobTable from './jobSearchData';
import { FaEnvelope, FaPhone, FaLinkedin, FaVideo } from "react-icons/fa";
import SendEmail from "./sharedComponents/sendEmail/SendEmail.jsx";
import MakeCall from './sharedComponents/makeCall/MakeCall.jsx';
import LinkedInCompanyProfileDisplay from './companyProfileData';

const MessageList = ({
  messages,
  onEditMessage,
  editingMessageId,
  onCancelEdit,
  onSaveEdit,
  reviewData,
  onReviewAction,
  isTyping,
  showWelcomeMessage,
  onToggleWelcomeMessage,
  onExamplePromptClick,
  isOpenedByAiCampaign,
  onSaveContacts,
  isEnriched,
  handleSelectedSequence,
  handleSelectedContactList,
  handleSelectDropTime,
  handleSaveToOnepgrContactlist,
  onTriggerEnrichment
}) => {
  const messagesEndRef = useRef(null);
  const messageListRef = useRef(null);
  const [user, setUser] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const [showExamples, setShowExamples] = useState(true);
  const [hasUserMessage, setHasUserMessage] = useState(false);
  const [showSaveInterface, setShowSaveInterface] = useState(false);
  const [currentPeopleData, setCurrentPeopleData] = useState(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const { loading, handleSave, currentContactListList_id, contactlistIdref } = useOnePgrFunctions();
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [savedContacts, setSavedContacts] = useState(false);
  const [isNearBottom, setIsNearBottom] = useState(true);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [selectedMessageToSend, setSelectedMessageToSend] = useState("");
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);

  console.log(messages)

  // Function to check if scroll is near bottom
  const checkIfNearBottom = () => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
      const scrollThreshold = 100; // pixels from bottom
      const bottomPosition = scrollHeight - scrollTop - clientHeight;
      return bottomPosition <= scrollThreshold;
    }
    return true;
  };

  // Improved scroll to bottom function
  const scrollToBottom = (force = false) => {
    if (!messageListRef.current) return;

    const container = messageListRef.current;

    // Add a small delay to ensure content is rendered
    setTimeout(() => {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: force ? 'auto' : 'smooth'
      });
    }, 0);
  };

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      setIsNearBottom(checkIfNearBottom());
    };

    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener('scroll', handleScroll);
      return () => messageList.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // Handle messages updates
  useEffect(() => {
    const filteredMessages = messages.filter(message =>
      message &&
      (message.type === 'human' ?
        message.message !== null &&
        message.message !== undefined &&
        message.message !== '' :
        true
      )
    );

    setAllMessages([...getExamplePrompts(), ...filteredMessages]);

    // Scroll to bottom when new messages arrive or when typing
    if (isNearBottom || isTyping) {
      scrollToBottom(true);
    }
  }, [messages, isTyping]);

  // Handle initial render and welcome message
  useEffect(() => {
    if (showWelcomeMessage) {
      scrollToBottom(true);
    }
  }, [showWelcomeMessage]);

  // Handle content load
  const onContentLoad = () => {
    if (isNearBottom) {
      scrollToBottom();
    }
  };


  const formatMessageTime = (time) => {
    return moment(time).format('DD MMM hh:mm A'); // Changed format to use 12-hour with AM/PM
  };


  const handleSaveToOnePgr = (peopleData) => {
    setCurrentPeopleData(peopleData);
    setShowSaveInterface(true);
  };

  const handleSaveConfirm = async (listName) => {
    console.log("people data to save ->", currentPeopleData, listName)
    if (!currentPeopleData) {
      showNotification('No data to save', 'error');
      return;
    }

    const success = await handleSave(listName, currentPeopleData, showNotification);
    console.log('success---------->', success, contactlistIdref.current);
    handleSaveToOnepgrContactlist(contactlistIdref.current)

    if (success) {
      showNotification('Contacts saved successfully', 'success');
      setShowSaveInterface(false);
      setCurrentPeopleData(null);
      setSavedContacts(true)
    }
  };

  const handleSaveCancel = () => {
    setShowSaveInterface(false);
    setCurrentPeopleData(null);
  };

  const showNotification = (message, type) => {
    // Implement your notification logic here
    console.log(`${type}: ${message}`);
    // You can use a toast library or custom notification component
  };



  useEffect(() => {
    // Improved filtering to remove null messages
    const filteredMessages = messages.filter(message =>
      message &&
      (message.type === 'human' ?
        message.message !== null &&
        message.message !== undefined &&
        message.message !== '' :
        true
      )
    );

    // Always include example prompts
    setAllMessages([...getExamplePrompts(), ...filteredMessages,]);


  }, [messages, isTyping]);

  useEffect(() => {

    const userData = JSON.parse(localStorage.getItem('user'));
    setUser(userData);
  }, []);

  useEffect(() => {
    // Check if there's a user message
    const userMessageExists = messages.some(message => message.type === 'human');
    setHasUserMessage(userMessageExists);

    // Only hide examples automatically if it's the first user message
    if (userMessageExists && !hasUserMessage) {
      setShowExamples(true);
    }

    if (showWelcomeMessage && showExamples) {
      setAllMessages([...getExamplePrompts(), ...messages,]);
    } else {
      setAllMessages(messages);
    }

  }, [messages, isTyping, showWelcomeMessage, showExamples]);


  const getExamplePrompts = () => {
    if (isOpenedByAiCampaign) {
      return [
        {
          id: 'find-people-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>To Create Campaign Plan:</h3>
              <ul className="example-prompt-list">
                {[
                  "Create a campaign for financial service company for sales leaders that are between 5to10 million",
                  // "Develop a campaign for financial services company targeting sales leaders managing portfolios ranging from $5 to $10 million",
                  // "Develop a campaign for real estate firm focused on property developers with project budgets ranging from $50 to $100 million",
                  // "Create a marketing campaign for luxury retail brand aimed at high-net-worth individuals managing portfolios between $1 to $3 million"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Try →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    } else {
      return [
        {
          id: 'find-people-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px' }}>To find people:</h3>
              <ul className="example-prompt-list">
                {[
                  // "Find CMO working at Rubrik in California",
                  "Find manager working at rubrik in california",
                  "Look for VP of sales, manager, and CTO at fintech in united states",
                  "Find manager at SaaS companies in united states"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Try →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        },
        {
          id: 'linkedin-post-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px' }}>To find people who liked and commented on LinkedIn posts:</h3>
              <ul className="example-prompt-list">
                {[
                  "Find people in this LinkedIn post:https://www.linkedin.com/posts/saas-north-conference_cue-the-confetti-sound-the-alarmsthesaas-activity-7244773707905146880-yhDP?utm_source=share&utm_medium=member_desktop who liked and commented",
                  // "Get a list of VPs who commented on this viral post about AI: https://www.linkedin.com/posts/duvvurukishore_custom-prototype-activity-7241287243821170688-3Uro?utm_source=share&utm_medium=member_desktop",
                  // "Find CTOs who liked or commented on this tech announcement: [Post URL]"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Try →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        },
        {
          id: 'web-scraper-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px' }}>To search in web:</h3>
              <ul className="example-prompt-list">
                {[
                  "Scrape google.com for US election updates",
                  "Extract data from google.com about latest AI startups",
                  "Gather information from google.com about global markets"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Try →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        },
        {
          id: 'create-sequence-examples',
          type: 'system',
          message: (
            <div>
              <h3 style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '5px', marginTop: '10px' }}>To create sequences:</h3>
              <ul className="example-prompt-list">
                {[
                  "Create a sequence for VPs of Marketing focusing on AI adoption using email",
                  // "Build a campaign for CFOs interested in financial technology innovations via phone calls and email",
                  // "Design an outreach sequence for HR Managers hiring for tech roles through LinkedIn and Twitter"
                ].map((prompt, index) => (
                  <li key={index}>
                    <span>{prompt}</span>
                    <button onClick={() => onExamplePromptClick(prompt)} className="try-button">
                      Try →
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )
        }
      ];
    }
  }

  const handleSequenceListData = (sequenceList, isFromSequenceExe) => {
    handleSelectedSequence(sequenceList, isFromSequenceExe);
  }

  const parseEmailContent = (content) => {
    // First check if content is an object with a content property
    if (typeof content === 'object' && content.content) {
      content = content.content;
    }

    if (typeof content === 'string') {
      // First try to match the formal "Subject:" and "Body:" format
      const formalSubjectMatch = content.match(/Subject:(.*?)(?=\n)/);
      const formalBodyMatch = content.match(/Body:\n([\s\S]*$)/);

      if (formalSubjectMatch && formalBodyMatch) {
        const emailContent = {
          subject: formalSubjectMatch[1].trim(),
          body: formalBodyMatch[1].trim()
        };
        // console.log('Parsed Email Content (Formal Format):', emailContent);
        return emailContent;
      }

      // Try to match just "Subject:" followed by content
      const subjectMatch = content.match(/Subject:(.*?)(?=\n)/);
      if (subjectMatch) {
        // Get everything after the subject line
        const parts = content.split('\n');
        const subjectIndex = parts.findIndex(line => line.includes('Subject:'));

        if (subjectIndex !== -1) {
          const subject = subjectMatch[1].trim();
          // Join all lines after the subject line
          const body = parts.slice(subjectIndex + 1).join('\n').trim();

          const emailContent = {
            subject: subject,
            body: body
          };
          console.log('Parsed Email Content (Simple Format):', emailContent);
          return emailContent;
        }
      }

      // If the string starts with "subject:" (case insensitive)
      const simpleMatch = content.match(/^subject:\s*(.*?)\n([\s\S]*$)/i);
      if (simpleMatch) {
        const emailContent = {
          subject: simpleMatch[1].trim(),
          body: simpleMatch[2].trim()
        };
        console.log('Parsed Email Content (Case Insensitive):', emailContent);
        return emailContent;
      }
    }
    return null;
  };

  const handleSelectedContactLists = (contactList) => {
    handleSelectedContactList(contactList);
  }

  const HandleSelectedDropTime = (selectedDropTime) => {
    handleSelectDropTime(selectedDropTime);
  }


  const renderMessageContent = (message) => {
    if (!message) {
      console.warn('Received undefined message');
      return null;
    }

    let content = message.message;

    // If content is a string, try to parse it as JSON
    if (typeof content === 'string') {
      try {
        content = JSON.parse(content);
      } catch (e) {
        // Check if the string contains email format
        const emailContent = parseEmailContent(content);
        if (emailContent) {
          return (
            <div className="email-data-container">
              <ContentComposer
                content={emailContent}
                type="email"
              />
            </div>
          );
        }
      }
    }

    if (message.type === 'system') {
      return content;
    } else if (message.type === 'human') {
      return <p>{content || ''}</p>;
    } else if (message.type === 'ai' || message.type === 'bot' || message.type === 'general' || message.type === 'testMessage') {
      // Handle the case where content is an object with a 'content' property
      if (content && typeof content === 'object' && content.content) {
        return (
          <div className="markdown-content general-message">
            <ReactMarkdown>{content.content}</ReactMarkdown>
          </div>
        );
      }

      // Handle insights
      if (content && content.insights) {
        let insights = content.insights;
        if (typeof insights === 'string') {
          insights = insights.split('\n').filter(item => item.trim() !== '');
        }
        return (
          <div className="insights-container">
            <h3>Insights:</h3>
            <ul>
              {insights.map((insight, index) => (
                <li key={index}>{insight}</li>
              ))}
            </ul>
            <p>* Contact location may differ from company location</p>
          </div>
        );
      }


      // Handle peopleData
      if (content && content.peopleData) {
        return (
          <div className="people-data-container">
            <PeopleTable people={content.peopleData}

              onEnrichContacts={onTriggerEnrichment}
            />

          </div>
        );
      }

      // testing response
      if (content && content.testResponse) {
        return (
          <div className="test-response">
            <TextAreaForTesting testResponse={content.testResponse} />

          </div>
        );
      }

      //enriched people data
      if (content && content.enrichedPeopleData) {
        return (
          <div className="people-data-container">
            <EnrichedPeopleTable people={content.enrichedPeopleData}
              handleSaveToOnePgr={handleSaveToOnePgr}
              savedContacts={savedContacts}
            />
          </div>
        );
      }

      // Handle linkedInData
      if (content && content.linkedInData) {
        return <LinkedInDataTable data={content.linkedInData}
          onEnrichContacts={onTriggerEnrichment}
        />;
      }

      // Handle companyData
      if (content && content.companyData) {
        return <CompanyListTable companies={content.companyData} />;
      }

      // Handle emailData
      if (content && content.emailData) {
        return (
          <div className="email-data-container">
            <ContentComposer
              content={content.emailData}
              type="email"
            />
          </div>
        );
      }

      // Show sequence List

      if (content && content.sequenceList) {
        return <SequenceList sequenceList={content.sequenceList}
          isFromSequenceExe={content.isFromSequenceExe}
          handleSequenceListData={handleSequenceListData}
        />;
      }

      if (content && content.selectContactList || content.selectDropTime || content.newlyCreatedSequence) {
        return <SelectListDropTime contactList={content.selectContactList}
          isSelectContactList={content.selectContactList ? true : false}
          isSelectDropTime={content.selectDropTime ? true : false}
          handleContactListChange={handleSelectedContactLists}
          handlDropTimeChane={HandleSelectedDropTime}
          newlyCreatedSequence={content.newlyCreatedSequence}
        />;
      }

      if (content && content.viewSequenceReport) {
        return <SelectListDropTime viewSequenceReport={content.viewSequenceReport}
          isViewSequenceReport={true}
        />;
      }


      // Handle genAIPromptData
      if (content && content.genAIPromptData) {

        return (
          <div className="gen-ai-prompt-container">
            <ContentComposer
              content={content.genAIPromptData}
              type="gen AI prompt"
            />
          </div>
        );
      }

      // Handle webData
      if (content && content.webData) {
        return (
          <div className="web-data-container">
            <WebContent data={content.webData} />
          </div>
        );
      }

      // Ip enrichment
      if (content && content.ipEnrich) {
        return (
          <div className="web-data-container">
            <IpEnrichment data={content.ipEnrich?.data} />
          </div>
        );
      }
      // company employee data
      if (content && content.employeeData) {
        return (
          <div className="web-data-container">
            <EmployeeTable data={content.employeeData}
              onEnrichContacts={onTriggerEnrichment}
            />
          </div>
        );
      }

      // job search data table
      if (content && content.jobData) {
        return (
          <div className="web-data-container">
            <JobTable data={content.jobData}

            />
          </div>
        );
      }

      // runway video api 
      if (content && content.creaetedVideoURl) {
        return (
          <div className="web-data-container">
            <RunwayVideoCreator data={content.creaetedVideoURl} />
          </div>
        );
      }
      // linkedin profile data 
      if (content && content.linkedinProfileData) {
        return (

          <LinkedInProfileDisplay data={content.linkedinProfileData} />

        );
      }

       // linkedin company profile data 
       if (content && content.companyProfileData) {
        return (

          <LinkedInCompanyProfileDisplay data={content.companyProfileData} />

        );
      }

      // If content is a string, render it as markdown
      if (typeof content === 'string') {
        return (
          <div className="markdown-content">
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        );
      }


      // Fallback for any other type of content
      return <p>{JSON.stringify(content) || 'No content'}</p>;
    }

    console.warn('Unhandled message type or structure:', message);
    return <p>Unknown message type</p>;
  };

  // Fallback for any other type of content

  const getAvatarContent = () => {
    if (!user) return '';
    if (user.avatar) return <img src={user.avatar} alt="User avatar" className="user-avatar-img" />;
    return user.name.charAt(0).toUpperCase();
  };

  const handleToggleExamples = () => {
    const newShowExamples = !showExamples;
    setShowExamples(newShowExamples);
    onToggleWelcomeMessage(newShowExamples);
  };

  const toggleSendEmailModal = (isOpen, message) => {
    if (isOpen) {
      setSelectedMessageToSend(message.message)
    }
    setIsEmailModalOpen(isOpen);
  };

  const sendEmailAction = (emailData) => {
    console.log("Email data to be sent:", emailData);
    // Perform email sending logic here (e.g., API call)
    toggleSendEmailModal(false);
  };

  const renderActionButtonsForAiMessage = (message) => {
    const sendMessage = checkToDisplayActionButtons(message.message);
    if (!sendMessage) return null
    return (
      <div className="action-icons-container">
        <FaEnvelope
          title="Send Email"
          className="action-icon"
          onClick={() => toggleSendEmailModal(true, message)}
        />
        <FaPhone title="Make Call" className="action-icon"
          onClick={() => toggleMakeCallModal(true)}
        />
        {/* <FaLinkedin title="Send Linkedin Request" className="action-icon" />
        <FaVideo title="Record Video Message" className="action-icon" /> */}
      </div>
    );
  };

  const checkToDisplayActionButtons = (content) => {
    if (typeof content === 'string') {
      try {
        content = JSON.parse(content);
      } catch (e) {
        return content
      }
    }

    if (content && content.insights) {
      let insights = content.insights;
      if (typeof insights === 'string') {
        insights = insights.split('\n').filter(item => item.trim() !== '');
      }
      return insights
    }
    if (content && content.creaetedVideoURl) {
      return true
    } if (content && content.emailData) {
      return true;
    }

  }

  const toggleMakeCallModal = (isOpen) => {
    setIsCallModalOpen(isOpen);
  };



  return (
    <div className="message-list-container" ref={messageListRef} style={{
      height: '100%',
      overflowY: 'auto',
      position: 'relative',
      scrollBehavior: 'smooth'
    }}>
      <div className="welcome-message-toggle">
        {/* <button onClick={handleToggleExamples}>
          {showExamples ? 'Hide Example Prompts' : 'Show Example Prompts'}
        </button> */}
      </div>
      <div className="message-list">
        {allMessages.map((message, index) => (
          message.message && <div key={`message-${index}`} className={`message-container ${message.type}-container`} onLoad={onContentLoad} >
            {message.type === 'human' && message.id === editingMessageId ? (
              <EditableMessage
                message={message}
                onSave={(editedContent) => onSaveEdit(message.id, editedContent)}
                onCancel={onCancelEdit}
              />
            ) : (
              <div className={`message ${message.type}-message`}>
                {message.type === 'human' ? (
                  message.message && (
                    <div className="user-message-container">
                      <div className="user-avatar-message">
                        {getAvatarContent()}
                      </div>
                      <div className="user-message user-text">
                        {renderMessageContent(message)}
                        <div className="message-timestamp">
                          {formatMessageTime(message.time)}
                        </div>
                        <button onClick={() => onEditMessage(message.id)} className="edit-button">
                          <span className="edit-icon"><FontAwesomeIcon icon={faPencilAlt} /></span>
                          <span className="edit-text">Edit</span>
                        </button>
                      </div>
                    </div>
                  )
                ) : (
                  message.message && (
                    <div className="ai-message-container">
                      <div className="ai-avatar-message">
                        AI
                      </div>
                      <div className="ai-message ai-text">
                        {renderMessageContent(message)}
                        <div className="message-timestamp">
                          {formatMessageTime(message.time)}
                        </div>
                      </div>
                      {renderActionButtonsForAiMessage(message)}

                    </div>

                  )
                )}

              </div>
            )}


            {index === allMessages.length - 1 && message.type === 'human' && reviewData && (
              <ReviewPrompt
                data={reviewData}
                onAction={onReviewAction}
              />
            )}
          </div>

        ))}

        {isTyping && (
          <div className="elegant-ai-indicator">
            <div className="text">AI Processing</div>
            <div className="orb"></div>
            <div className="orb"></div>
            <div className="orb"></div>
          </div>
        )}

      </div>

      {showSaveInterface && (
        <OnePgrSaveInterface
          handleSaveConfirm={handleSaveConfirm}
          onCancel={handleSaveCancel}
        />
      )}
      {isEmailModalOpen && (
        <SendEmail
          toggleSendEmailModal={toggleSendEmailModal}
          sendEmailAction={sendEmailAction}
          selectedMessageToSend={selectedMessageToSend}
        />
      )}
      {isCallModalOpen && (
        <MakeCall
          toggleMakeCallModal={toggleMakeCallModal}
        />
      )}
      <div
        ref={messagesEndRef}
        style={{ float: 'left', clear: 'both' }}
      />
    </div>
  );
};

export default MessageList;