import React, { useState, useEffect } from 'react';
import '../styles/enrichedPeopleTable.css'
import { faPencilAlt, faSave, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EnrichedPeopleTable = ({ people, onSaveContacts, handleSaveToOnePgr, savedContacts }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [filter, setFilter] = useState('');
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [showEnrichedData, setShowEnrichedData] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    useEffect(() => {
        if (savedContacts) {
            setShowSuccessMessage(true);
            setSelectedContacts([]);
        } else {
            setShowSuccessMessage(false);
        }
    }, [savedContacts]);

    const handleCheckboxChange = (person) => {
        setSelectedContacts(prev =>
            prev.some(contact => contact.id2 === person.id2)
                ? prev.filter(contact => contact.id2 !== person.id2)
                : [...prev, person]
        );
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedContacts(filteredPeople);
        } else {
            setSelectedContacts([]);
        }
    };

    const sortedPeople = React.useMemo(() => {
        let sortablePeople = [...people];
        if (sortConfig.key !== null) {
            sortablePeople.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortablePeople;
    }, [people, sortConfig]);

    const formatPerson = (person) => {
        const formatLocation = () => {
            if (person.enriched_location) return person.enriched_location;
            if (person.location) return person.location;
            const city = person.city || '';
            const country = person.country || '';
            return city && country ? `${city}, ${country}` : city || country || 'N/A';
        };

        const formatEmail = () => {
            if (person.enriched_email) return person.enriched_email;
            return person.email === 'email_not_unlocked@domain.com' ? 'no email found' : (person.email || 'no email found');
        };

        const formatLinkedInUrl = () => {
            if (person.enriched_linkedin_url && person.enriched_linkedin_url !== 'null') {
                return person.enriched_linkedin_url;
            }
            if (person.linkedin_url && person.linkedin_url !== 'null') {
                return person.linkedin_url;
            }
            if (person.profileLink && person.profileLink !== 'null') {
                return person.profileLink;
            }
            return 'N/A';
        };

        // Add new function to handle title
        const formatTitle = () => {
            if (person.enriched_title && person.enriched_title !== 'null') {
                return person.enriched_title;
            }
            if (person.title && person.title !== 'null') {
                return person.title;
            }
            if (person.job && person.job !== 'null') {
                return person.job;
            }
            return 'N/A';
        };

        return {
            name: person.enriched_name || person.name || `${person.first_name || person.firstName} ${person.last_name || person.lastName}`,
            title: formatTitle(),  // Use the new formatTitle function
            company: person.enriched_company || person.company || person.company_name || person.organization_name || (person.organization && person.organization.name) || 'N/A',
            location: formatLocation(),
            email: formatEmail() || person.enriched_people.enriched_email,
            phone: person.enriched_phone || (person.phone_numbers && person.phone_numbers.length > 0 ? person.phone_numbers[0].number : 'N/A') || 'N/A',
            linkedin_url: formatLinkedInUrl(),
            isEnriched: !!(person.enriched_name || person.enriched_title || person.enriched_company || person.enriched_location || person.enriched_email || person.enriched_phone || person.enriched_linkedin_url)
        };
    };

    const filteredPeople = sortedPeople.filter(person => {
        const searchStr = filter.toLowerCase();
        const formattedPerson = formatPerson(person);
        return Object.values(formattedPerson).some(value =>
            value.toString().toLowerCase().includes(searchStr)
        );
    });

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };


    const getEnrichmentStats = (peopleData) => {
        return peopleData.reduce((stats, person) => {
            const formattedPerson = formatPerson(person);

            // Check for valid email (not N/A or Locked)
            if (formattedPerson.email && formattedPerson.email !== 'N/A' && formattedPerson.email !== 'Locked' &&  formattedPerson.email !== 'no email found'  &&  formattedPerson.email !== 'Email not found' ) {
                stats.emailCount++;
            }

            // Check for valid phone (not N/A)
            if (formattedPerson.phone && formattedPerson.phone !== 'N/A') {
                stats.phoneCount++;
            }

            // Count people with both email and phone
            if (
                formattedPerson.email &&
                formattedPerson.email !== 'N/A' &&
                formattedPerson.email !== 'Locked' &&
                formattedPerson.phone &&
                formattedPerson.phone !== 'N/A'
            ) {
                stats.bothCount++;
            }

            return stats;
        }, { emailCount: 0, phoneCount: 0, bothCount: 0 });
    };


    return (
        <div className="enriched-table-container">
            <table className="enriched-table">
                <thead>
                    <tr>
                        <th>
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={selectedContacts.length === filteredPeople.length && filteredPeople.length > 0}
                                className="contact-checkbox"
                            />
                        </th>
                        <th className="name-header">
                            <div className="name-header-content">

                                <span onClick={() => requestSort('name')}>Name</span>
                                <span className="sort-indicator">
                                    {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                                </span>
                            </div>
                        </th>
                        <th onClick={() => requestSort('title')}>
                            Title
                            <span className="sort-indicator">
                                {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                            </span>
                        </th>
                        <th onClick={() => requestSort('company')}>
                            Company
                            <span className="sort-indicator">
                                {sortConfig.key === 'company' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                            </span>
                        </th>
                        <th onClick={() => requestSort('location')}>
                            Location
                            <span className="sort-indicator">
                                {sortConfig.key === 'location' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
                            </span>
                        </th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>LinkedIn</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredPeople.map((person, index) => {
                        const formattedPerson = formatPerson(person);
                        const rowClassName = formattedPerson.isEnriched && showEnrichedData ? 'enriched-row' : '';

                        return (
                            <tr key={person.id2 || index} className={rowClassName}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedContacts.some(contact => contact.id2 === person.id2)}
                                        onChange={() => handleCheckboxChange(person)}
                                        className="contact-checkbox"
                                    />
                                </td>
                                <td>
                                    <div className="name-cell">

                                        <span>{formattedPerson.name}</span>
                                    </div>
                                </td>
                                <td title={formattedPerson.title}>
                                    {formattedPerson.title !== 'N/A' && formattedPerson.title ? (
                                        <span className="title-cell">{formattedPerson.title}</span>
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td>{formattedPerson.company}</td>
                                <td>{formattedPerson.location}</td>
                                <td>
                                    {formattedPerson.email !== 'Locked'
                                        ? formattedPerson.email
                                        : <span className="locked-content">Locked</span>}
                                </td>
                                <td>{formattedPerson.phone}</td>
                                <td>
                                    {formattedPerson.linkedin_url !== 'N/A' && formattedPerson.linkedin_url ? (
                                        <a
                                            href={formattedPerson.linkedin_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="linkedin-link"
                                        >
                                            Profile
                                        </a>
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="table-footer">
                <div className="enrichment-stats">
                    <div className="stats-container">
                        <span>Total people: {filteredPeople.length}</span>
                        <span className="stats-divider">|</span>
                        <span>Enriched with email: {getEnrichmentStats(filteredPeople).emailCount}</span>
                        <span className="stats-divider">|</span>
                        <span>Enriched with phone: {getEnrichmentStats(filteredPeople).phoneCount}</span>
                        <span className="stats-divider">|</span>
                        <span>Enriched with both: {getEnrichmentStats(filteredPeople).bothCount}</span>
                    </div>
                </div>
                <div className="save-section">
                    {showSuccessMessage && (
                        <div className="success-message">
                            <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '5px', color: '#28a745' }} />
                            Contacts saved successfully to OnePgr contact list
                        </div>
                    )}
                    <button
                        className={`save-to-button ${selectedContacts.length === 0 ? 'disabled' : ''}`}
                        onClick={() => handleSaveToOnePgr(selectedContacts)}
                        disabled={selectedContacts.length === 0}
                    >
                        <FontAwesomeIcon icon={faSave} style={{ marginRight: '5px' }} />
                        Save to OnePgr Contacts ({selectedContacts.length})
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EnrichedPeopleTable;