// import React from 'react';
// import { Briefcase, GraduationCap, Award, MapPin, Link, Users, Building } from 'lucide-react';
// import styled from 'styled-components';

// // Styled Components
// const ProfileContainer = styled.div`
//   max-width: 900px;
//   margin: 2rem auto;
//   background: #f8fafc;
//   border-radius: 12px;
//   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
// `;

// const ProfileHeader = styled.div`
//   background: white;
//   border-radius: 12px 12px 0 0;
//   overflow: hidden;
// `;

// const CoverImage = styled.div`
//   height: 140px;
//   background: linear-gradient(135deg, #0077b5 0%, #00a0dc 100%);
//   position: relative;
// `;

// const ProfileImage = styled.div`
//   position: absolute;
//   bottom: -50px;
//   left: 24px;
//   width: 120px;
//   height: 120px;
//   border-radius: 50%;
//   border: 4px solid white;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   overflow: hidden;
//   background: white;

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// `;

// const ProfileInfo = styled.div`
//   padding: 1.5rem 2rem 2rem;
//   margin-top: 50px;

//   h1 {
//     font-size: 1.875rem;
//     font-weight: 700;
//     color: #1a1a1a;
//     margin-bottom: 0.5rem;
//   }

//   h2 {
//     font-size: 1.25rem;
//     color: #4b5563;
//     margin-bottom: 1rem;
//   }
// `;

// const LocationInfo = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
//   color: #6b7280;
//   font-size: 0.875rem;

//   svg {
//     width: 16px;
//     height: 16px;
//   }
// `;

// const Section = styled.div`
//   background: white;
//   margin-top: 1rem;
//   padding: 1.5rem 2rem;
//   border-radius: 8px;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

//   h3 {
//     font-size: 1.25rem;
//     font-weight: 600;
//     color: #1a1a1a;
//     margin-bottom: 1.5rem;
//     display: flex;
//     align-items: center;
//     gap: 0.75rem;

//     svg {
//       color: #0077b5;
//     }
//   }
// `;

// const ExperienceItem = styled.div`
//   display: flex;
//   gap: 1rem;
//   padding-bottom: 1.5rem;
//   margin-bottom: 1.5rem;
//   border-bottom: 1px solid #e5e7eb;
  
//   &:last-child {
//     border-bottom: none;
//     margin-bottom: 0;
//     padding-bottom: 0;
//   }
// `;

// const CompanyLogo = styled.div`
//   width: 48px;
//   height: 48px;
//   border-radius: 8px;
//   overflow: hidden;
//   flex-shrink: 0;

//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: contain;
//   }
// `;

// const JobInfo = styled.div`
//   flex: 1;

//   h4 {
//     font-weight: 600;
//     color: #1a1a1a;
//     margin-bottom: 0.25rem;
//   }

//   .company {
//     color: #4b5563;
//     font-size: 0.9375rem;
//   }

//   .duration {
//     color: #6b7280;
//     font-size: 0.875rem;
//     margin: 0.25rem 0;
//   }

//   .description {
//     color: #4b5563;
//     font-size: 0.9375rem;
//     line-height: 1.5;
//     margin-top: 0.75rem;
//   }
// `;

// const SkillsGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
//   gap: 1rem;
// `;

// const SkillItem = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0.75rem 1rem;
//   background: #f8fafc;
//   border-radius: 6px;
//   transition: all 0.2s ease;

//   &:hover {
//     background: #f1f5f9;
//     transform: translateY(-1px);
//   }

//   .skill-name {
//     font-weight: 500;
//     color: #1a1a1a;
//   }

//   .endorsements {
//     background: #e0f2fe;
//     color: #0369a1;
//     padding: 0.25rem 0.75rem;
//     border-radius: 999px;
//     font-size: 0.75rem;
//     font-weight: 500;
//   }
// `;

// const LinkedInButton = styled.a`
//   display: inline-flex;
//   align-items: center;
//   gap: 0.5rem;
//   padding: 0.625rem 1rem;
//   background: #0077b5;
//   color: white;
//   border-radius: 6px;
//   font-weight: 500;
//   font-size: 0.875rem;
//   text-decoration: none;
//   transition: all 0.2s ease;

//   &:hover {
//     background: #005885;
//   }
// `;

// const LinkedInProfileDisplay = ({ data }) => {
//   if (!data) {
//     return <div>No profile data available</div>;
//   }

//   const { general, jobs, schools, skills } = data;

//   return (
//     <ProfileContainer>
//       <ProfileHeader>
//         <CoverImage>
//           <ProfileImage>
//             {general.imgUrl ? (
//               <img src={general.imgUrl} alt={general.fullName} />
//             ) : (
//               <Building size={60} color="#9ca3af" />
//             )}
//           </ProfileImage>
//         </CoverImage>
        
//         <ProfileInfo>
//           <h1>{general.fullName}</h1>
//           <h2>{general.headline}</h2>
//           <LocationInfo>
//             <MapPin />
//             <span>{general.location}</span>
//             <span>•</span>
//             <Users />
//             <span>{general.connectionsCount} connections</span>
//           </LocationInfo>
//           <p className="mt-4 text-gray-600">{general.description}</p>
//         </ProfileInfo>
//       </ProfileHeader>

//       <Section>
//         <h3>
//           <Briefcase />
//           Experience
//         </h3>
//         {jobs.map((job, index) => (
//           <ExperienceItem key={index}>
//             <CompanyLogo>
//               {job.logoUrl ? (
//                 <img src={job.logoUrl} alt={job.companyName} />
//               ) : (
//                 <Building size={24} color="#9ca3af" />
//               )}
//             </CompanyLogo>
//             <JobInfo>
//               <h4>{job.jobTitle}</h4>
//               <div className="company">{job.companyName}</div>
//               <div className="duration">{job.dateRange} • {job.duration}</div>
//               {job.location && <div className="duration">{job.location}</div>}
//               <div className="description">{job.description}</div>
//             </JobInfo>
//           </ExperienceItem>
//         ))}
//       </Section>

//       <Section>
//         <h3>
//           <GraduationCap />
//           Education
//         </h3>
//         {schools.map((school, index) => (
//           <ExperienceItem key={index}>
//             <CompanyLogo>
//               {school.logoUrl ? (
//                 <img src={school.logoUrl} alt={school.schoolName} />
//               ) : (
//                 <GraduationCap size={24} color="#9ca3af" />
//               )}
//             </CompanyLogo>
//             <JobInfo>
//               <h4>{school.schoolName}</h4>
//               <div className="description">{school.degree}</div>
//             </JobInfo>
//           </ExperienceItem>
//         ))}
//       </Section>

//       <Section>
//         <h3>
//           <Award />
//           Skills
//         </h3>
//         <SkillsGrid>
//           {skills
//             .sort((a, b) => b.endorsements - a.endorsements)
//             .slice(0, 12)
//             .map((skill, index) => (
//               <SkillItem key={index}>
//                 <span className="skill-name">{skill.name}</span>
//                 <span className="endorsements">{skill.endorsements}</span>
//               </SkillItem>
//             ))}
//         </SkillsGrid>
//       </Section>
//     </ProfileContainer>
//   );
// };

// export default LinkedInProfileDisplay;


import React from 'react';
import { Briefcase, GraduationCap, Award, MapPin, Link, Users, Building } from 'lucide-react';
import '../styles/linkedinPofileDataTable.css';

const LinkedInProfileDisplay = ({ data }) => {
  if (!data) {
    return <div>No profile data available</div>;
  }

  const { general, jobs, schools, skills } = data;

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="cover-image">
          <div className="profile-image">
            {general.imgUrl ? (
              <img src={general.imgUrl} alt={general.fullName} />
            ) : (
              <Building size={60} color="#9ca3af" />
            )}
          </div>
        </div>
        
        <div className="profile-info">
          <h1>{general.fullName}</h1>
          <h2>{general.headline}</h2>
          <div className="location-info">
            <MapPin />
            <span>{general.location}</span>
            <span>•</span>
            <Users />
            <span>{general.connectionsCount} connections</span>
          </div>
          <p>{general.description}</p>
        </div>
      </div>

      <div className="section">
        <h3>
          <Briefcase />
          Experience
        </h3>
        {jobs.map((job, index) => (
          <div key={index} className="experience-item">
            <div className="company-logo">
              {job.logoUrl ? (
                <img src={job.logoUrl} alt={job.companyName} />
              ) : (
                <Building size={24} color="#9ca3af" />
              )}
            </div>
            <div className="job-info">
              <h4>{job.jobTitle}</h4>
              <div className="company">{job.companyName}</div>
              <div className="duration">{job.dateRange} • {job.duration}</div>
              {job.location && <div className="duration">{job.location}</div>}
              <div className="description">{job.description}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="section">
        <h3>
          <GraduationCap />
          Education
        </h3>
        {schools.map((school, index) => (
          <div key={index} className="experience-item">
            <div className="company-logo">
              {school.logoUrl ? (
                <img src={school.logoUrl} alt={school.schoolName} />
              ) : (
                <GraduationCap size={24} color="#9ca3af" />
              )}
            </div>
            <div className="job-info">
              <h4>{school.schoolName}</h4>
              <div className="description">{school.degree}</div>
            </div>
          </div>
        ))}
      </div>

      <div className="section">
        <h3>
          <Award />
          Skills
        </h3>
        <div className="skills-grid">
          {skills
            .sort((a, b) => b.endorsements - a.endorsements)
            .slice(0, 12)
            .map((skill, index) => (
              <div key={index} className="skill-item">
                <span className="skill-name">{skill.name}</span>
                <span className="endorsements">{skill.endorsements}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LinkedInProfileDisplay;