import React from 'react';
import { Building2, Globe, MapPin, Users, Award, Calendar, Briefcase } from 'lucide-react';
import '../styles/companyProfileData.css';

const LinkedInProfileDisplay = ({ data }) => {
  const company = data[0];

  return (
    <div className="profile-container">
      {/* Header Section */}
      <div className="profile-header">
        <h1 className="company-name">{company.companyName}</h1>
        <p className="tagline">{company.tagLine}</p>
      </div>

      {/* Key Info Cards */}
      <div className="info-grid">
        <div className="info-card">
          <div className="card-header">
            <Users className="card-icon" />
            <span className="card-title">Company Size</span>
          </div>
          <p>{company.companySize}</p>
        </div>

        <div className="info-card">
          <div className="card-header">
            <MapPin className="card-icon" />
            <span className="card-title">Location</span>
          </div>
          <p>{company.headquarters}</p>
        </div>

        <div className="info-card">
          <div className="card-header">
            <Calendar className="card-icon" />
            <span className="card-title">Founded</span>
          </div>
          <p>{company.founded}</p>
        </div>
      </div>

      {/* Description Section */}
      <div className="description-section">
        <div className="card-header">
          <Building2 className="card-icon" />
          <span className="card-title">About</span>
        </div>
        <p>{company.description}</p>
      </div>

      {/* Specialties Section */}
      <div className="info-card">
        <div className="card-header">
          <Award className="card-icon" />
          <span className="card-title">Specialties</span>
        </div>
        <div className="specialties-container">
          {company.specialties.split(', ').map((specialty, index) => (
            <span key={index} className="specialty-badge">
              {specialty}
            </span>
          ))}
        </div>
      </div>

      {/* Footer Stats */}
      <div className="stats-footer">
        <div className="stat-item">
          <Users className="card-icon" />
          <span>{company.followerCount.toLocaleString()} followers</span>
        </div>
        <div className="stat-item">
          <Briefcase className="card-icon" />
          <span>{company.employeesOnLinkedIn.toLocaleString()} employees on LinkedIn</span>
        </div>
        <div className="stat-item">
          <Globe className="card-icon" />
          <a 
            href={company.website} 
            className="website-link"
            target="_blank" 
            rel="noopener noreferrer"
          >
            {company.website}
          </a>
        </div>
      </div>
    </div>
  );
};

export default LinkedInProfileDisplay;