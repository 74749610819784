import React, { useState, useEffect } from 'react';
import { X, Plus } from 'lucide-react';
import "../styles/reviewPrompt.css";
import Tooltip from './tooltip';

const ReviewPrompt = ({ data, onAction }) => {
  const [editedData, setEditedData] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [addedFields, setAddedFields] = useState([]);

  useEffect(() => {
    let parsedIntents = [];
    if (typeof data.intents === 'string') {
      try {
        const jsonString = data.intents.replace(/```json\n|\n```/g, '');
        parsedIntents = JSON.parse(jsonString);
      } catch (error) {
        console.error("Error parsing intents JSON:", error);
      }
    } else if (Array.isArray(data.intents)) {
      parsedIntents = data.intents;
    } else if (data.intents && typeof data.intents === 'object') {
      parsedIntents = [data.intents];
    }

    setEditedData(parsedIntents.map(intent => ({
      ...intent,
      editedInfo: {...(intent.extractedInfo || {})}
    })));

    // Initialize available fields
    setAvailableFields(fields.map(field => field.key));
    
    // Initialize added fields
    setAddedFields(parsedIntents.map(() => []));
  }, [data]);

  const fields = [
    { key: 'title', label: 'Title' },
    { key: 'jobSignal', label: 'Job Signal' },
    { key: 'channel', label: 'Channel' },
    { key: 'company', label: 'Company' },
    { key: 'companySize', label: 'Company Size' },
    { key: 'location', label: 'Location' },
    { key: 'verticalIndustry', label: 'Vertical Industry' },
    { key: 'capitalRaised', label: 'Capital Raised' },
    { key: 'yearsInBusiness', label: 'Years in Business' },
    { key: 'typeOfBusiness', label: 'Type of Business' },
    { key: 'seniority', label: 'Seniority' },
    { key: 'recipient', label: 'Recipient' },
    { key: 'topic', label: 'Topic' },
    { key: 'command', label: 'Command' },
    { key: 'target', label: 'Target' },
    { key: 'website', label: 'Website' },
    { key: 'dataToExtract', label: 'Data to Extract' },
    { key: 'contactType', label: 'Contact Type' },
    { key: 'dataFields', label: 'Data Fields to Enrich' },
    { key: 'postUrl', label: 'LinkedIn Post URL' },
    { key: 'dataType', label: 'Data Type to Extract' },
    { key: 'postAuthor', label: 'Post Author' },
    { key: 'postDate', label: 'Post Date' },
    { key: 'emailType', label: 'Email Type' },
    { key: 'context', label: 'Context' },
    { key: 'promptType', label: 'Prompt Type' },
    { key: 'task', label: 'Task' },
    { key: 'aspect', label: 'Aspect' },
    { key: 'companyType', label: "Company Type"},
    { key: "sequenceName", label: "Sequence Name"},  
    { key: "reportType", label: "Report Type"}, 
    { key: 'executionType', label: "Execution Type"}, 
    { key: 'scheduledTime', label: "Scheduled Time"}, 
    { key: 'content', label: "Content"}, 
    { key: 'description',label: 'Description'}, 
    { key: 'ipAddress',label: 'ipAddress'}, 
    { key: 'profileUrl',label: 'profileUrl'}, 

  ];

  const handleRemoveIntent = (indexToRemove) => {
    setEditedData(prevData => prevData.filter((_, index) => index !== indexToRemove));
    setAddedFields(prevFields => prevFields.filter((_, index) => index !== indexToRemove));
  };

  const handleInputChange = (intentIndex, key, value) => {
    setEditedData(prevData => {
      const newData = [...prevData];
      newData[intentIndex].editedInfo[key] = value;
      return newData;
    });
  };

  const handleAddField = (intentIndex, field) => {
    setEditedData(prevData => {
      const newData = [...prevData];
      if (!newData[intentIndex].editedInfo[field]) {
        newData[intentIndex].editedInfo[field] = '';
      }
      return newData;
    });
    setAddedFields(prevFields => {
      const newFields = [...prevFields];
      if (!newFields[intentIndex]) {
        newFields[intentIndex] = [];
      }
      if (!newFields[intentIndex].includes(field)) {
        newFields[intentIndex] = [...newFields[intentIndex], field];
      }
      return newFields;
    });
  };

  const renderReviewContent = (intent, intentIndex) => {
    const fieldsToRender = new Set([
      ...Object.keys(intent.editedInfo).filter(key => intent.editedInfo[key] !== '' && intent.editedInfo[key] !== 'Not provided'),
      ...(addedFields[intentIndex] || [])
    ]);

    return Array.from(fieldsToRender).map(key => {
      const fieldInfo = fields.find(f => f.key === key);
      const label = fieldInfo ? fieldInfo.label : key;
      return (
        <div key={key} className="review-item">
          <label>{label}:</label>
          <input 
            type="text" 
            value={intent.editedInfo[key] || ''}
            onChange={(e) => handleInputChange(intentIndex, key, e.target.value)}
            className={(intent.mandatoryFields || []).includes(key) ? 'mandatory-field' : ''}
          />
        </div>
      );
    });
  };

  const isFieldEmpty = (value) => {
    if (typeof value === 'string') {
      return value.trim() === '';
    }
    return value == null || value === '';
  };

  const calculateScore = (intent) => {
    const mandatoryFields = intent.mandatoryFields || Object.keys(intent.extractedInfo || {});
    const filledMandatoryFields = mandatoryFields.filter(field => !isFieldEmpty(intent.editedInfo[field]));
    
    // Base score from confidence
    const baseScore = intent.confidence ? Math.round(intent.confidence * 100) : 0;
    
    // Extra score for additional fields (up to 7 points)
    const extraFields = Object.keys(intent.editedInfo).length - mandatoryFields.length;
    const extraFieldScore = Math.min(extraFields, 7);
  
    // Calculate total score
    let totalScore = baseScore + extraFieldScore;
  
    // Ensure the score is at least 70 if all mandatory fields are filled
    if (filledMandatoryFields.length === mandatoryFields.length && totalScore < 70) {
      totalScore = 70;
    }
  
    // Cap the score at 100
    return Math.min(Math.round(totalScore), 100);
  };

  const renderScoreAlert = (intent, intentIndex) => {
    const score = calculateScore(intent);
    const isScoreAboveMandate = score >= 70;
    const mandatoryFields = intent.mandatoryFields || Object.keys(intent.extractedInfo || {});
    const missingFields = mandatoryFields.filter(field => isFieldEmpty(intent.editedInfo[field]));

    if (isScoreAboveMandate && missingFields.length === 0) {
      return (
        <div className="score-alert good-score">
          <h4>Good Score!</h4>
          <p>
            This intent has a strong score of {score}%. You're ready to proceed with this well-structured {intent.type || 'unknown'} intent.
          </p>
        </div>
      );
    } else {
      return (
        <div className="score-alert low-score">
          <h4>Improve Your Score</h4>
          <p>
            The current score is {score}%. To enhance this {intent.type || 'unknown'} intent,  add the following key information:
          </p>
          <ul>
            {missingFields.map(field => {
              const fieldInfo = fields.find(f => f.key === field);
              return (
                <li key={field}>
                  {fieldInfo ? fieldInfo.label : field}
                  <Tooltip text={`Add ${fieldInfo ? fieldInfo.label : field} field`}>
                    <button 
                      onClick={() => handleAddField(intentIndex, field)}
                      className="add-field-button"
                      aria-label={`Add ${fieldInfo ? fieldInfo.label : field} field`}
                    >
                      <Plus size={12}  />
                      Add 
                    </button>
                  </Tooltip>
                </li>
              );
            })}
          </ul>
          {missingFields.length === 0 && (
            <p>
              Ensure all provided information is specific and detailed. 
              {intent.keywords && (
                <>Consider using keywords like: {intent.keywords.join(', ')}.</>
              )}
            </p>
          )}
        </div>
      );
    }
  };

  const isAllIntentsValid = () => {
    return editedData.every(intent => {
      const score = calculateScore(intent);
      const mandatoryFields = intent.mandatoryFields || Object.keys(intent.extractedInfo || {});
      return score >= 70 && mandatoryFields.every(field => !isFieldEmpty(intent.editedInfo[field]));
    });
  };

  if (!data || editedData.length === 0) {
    return <div>No data to review</div>;
  }

  return (
    <div className="review-prompt">
      <h2>Review Extracted Information</h2>
      {editedData.map((intent, index) => (
        <div key={index} className="intent-review">
          <div className="intent-header">
            <h3>Intent {index + 1}: {intent.type || 'Unknown'}</h3>
            <Tooltip text="Remove this intent">
              <button 
                onClick={() => handleRemoveIntent(index)}
                className="remove-intent-button"
                aria-label="Remove intent"
              >
                <X size={20} />
              </button>
            </Tooltip>
          </div>
          {renderScoreAlert(intent, index)}
          <div className="review-content">
            {renderReviewContent(intent, index)}
          </div>
        </div>
      ))}
      <div className="review-actions">
        <button 
          onClick={() => onAction('process', { ...data, intents: editedData })}
          disabled={!isAllIntentsValid() || editedData.length === 0}
          className="submit-button"
        >
          Submit
        </button>
        <button 
          onClick={() => onAction('general', data.input)}
          className="general-button"
        >
          Send General Prompt
        </button>
      </div>
    </div>
  );
};

export default ReviewPrompt;