

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
const API_IP = 'chatserver.onepgr.com';
// const API_IP = '127.0.0.1';
const API_PROTOCOL = 'https';
const API_BASE_URL = `${API_PROTOCOL}://${API_IP}`;

axios.defaults.withCredentials = true;

console.log('API Base URL:', API_BASE_URL);

export const sendProcessedDataToBackend = async (processedData) => {
  console.log(processedData)
  console.log("Sending data to backend:", JSON.stringify(processedData, null, 2));
  try {
    const response = await axios.post(`${API_BASE_URL}/api/process`, processedData);
    console.log("Response from backend:", response.data);

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error');
    }
  } catch (error) {
    console.error('API call failed:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
    }
    throw error;
  }
};

export const enrichPeopleData = async (peopleToEnrich, enrichmentType) => {
  try {
    const requestBody = {
      prompt: "enrich people",
      intents: [
        {
          type: "enrich",
          extractedInfo: {
            enrichType: enrichmentType,
            people: peopleToEnrich
          }
        }
      ]
    };
    console.log("Sending enrichment data to backend:", JSON.stringify(requestBody, null, 2));

    const response = await axios.post(`${API_BASE_URL}/api/process`, requestBody);
     console.log("enriched response--->",response)
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error');
    }
  } catch (error) {
    console.error('Enrichment API call failed:', error);
    throw error;
  }
};

export const testBackendConnection = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/test`);
    return response.data;
  } catch (error) {
    console.error('Backend connection test failed:', error);
    throw error;
  }
};

export const createNewChat = async (userId, type, domainName = '', linkedinUrl = '') => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/chat/new`, {
      user_id: userId,
      type,
      domain_name: domainName,
      linkdin_url: linkedinUrl
    });

    if (response.data.status === 1) {
      return response.data.sessionId;
    } else {
      throw new Error(response.data.message || 'Failed to create new chat');
    }
  } catch (error) {
    console.error('Error creating new chat:', error);
    throw error;
  }
};

export const fetchChatHistory = async (sessionId, userId, objectId = null) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/chat/history`, {
      sessionId,
      user_id: userId,
      object_id: objectId
    });

    // console.log('Raw response from server:', response.data); // Log the raw response

    if (response.data.status === 1 && Array.isArray(response.data.history)) {
      // Parse the history to match the expected format
      const parsedHistory = response.data.history.map(msg => {
        // console.log('Processing message:', msg);

        // Check if msg is an object and has the expected properties
        if (typeof msg === 'object' && msg !== null) {
          return {
            id: msg._id || msg.id || uuidv4(),
            type: msg.type || 'unknown',
            message: msg.message || (msg.data && msg.data.content) || '',
            time: msg.time || (msg.data && msg.data.time) || new Date().toISOString()
          };
        } else {
          console.warn('Unexpected message format:', msg);
          return null;
        }
      }).filter(Boolean); // Remove any null entries

      // console.log('Parsed history:', parsedHistory); // Log the parsed history
      return parsedHistory;
    } else {
      console.error('Unexpected response structure:', response.data);
      throw new Error(response.data.message || 'Failed to fetch chat history');
    }
  } catch (error) {
    console.error('Error fetching chat history:', error);
    throw error;
  }
};

export const getRecentChats = async (userId, type, objectId = null) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/sessions`, {
      user_id: userId,
      type,
      object_id: objectId
    });

    if (response.data.status === 1) {
      return response.data.accountSessions;
    } else {
      throw new Error(response.data.message || 'Failed to fetch recent chats');
    }
  } catch (error) {
    console.error('Error fetching recent chats:', error);
    throw error;
  }
};

export const sendMessageToChat = async (sessionId, userMessage, botMessage, userId, type, domainName = '', linkedinUrl = '') => {
  console.log(  "Sending message to chat:",  userMessage, botMessage);
  try {
    const response = await axios.post(`${API_BASE_URL}/api/chat/insert`, {
      sessionId: sessionId,
      userMessage: userMessage,
      botMessage: botMessage,
      userChatTime: new Date().toISOString(),
      aiChatTime: new Date().toISOString(),
      object_id: '',
      user_id: userId,
      type: type,
      domain_name: domainName,
      linkdin_url: linkedinUrl
    });

    if (response.data.status === 1) {
      return response.data;
    } else {
      throw new Error(response.data.message || 'Failed to send message');
    }
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

// New function for LangChain integration
export const processWithLangChain = async (input, chatHistory) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/process`, {
      input,
      chatHistory
    });

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error in LangChain processing');
    }
  } catch (error) {
    console.error('LangChain processing failed:', error);
    throw error;
  }
};

// Function to send reviewed data to backend
export const sendReviewedDataToBackend = async (reviewedData) => {
  try {
    const requestBody = {
      prompt: reviewedData.input,
      intents: reviewedData.intents.map(intent => ({
        type: intent.type,
        extractedInfo: Object.entries(intent.editedInfo || {}).reduce((acc, [key, value]) => {
          acc[key] = value === null ? "" : value;
          return acc;
        }, {})
      })),
      userId: reviewedData.userId,// Include the user ID in the request body
      sessionCookie: reviewedData.sessionCookie
    };
    console.log("Sending reviewed data to backend:", JSON.stringify(requestBody, null, 2));

    const response = await axios.post(`${API_BASE_URL}/api/process`, requestBody);

    console.log("Response from backend:", response.data);

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Unknown error in backend response');
    }
  } catch (error) {
    console.error('Error sending reviewed data to backend:', error);
    throw error;
  }
};

// export const processUserInput = async (input, sessionId) => {
//   try {
//     const response = await axios.post(`${API_BASE_URL}/api/process_user_input`, {
//       input,
//       sessionId
//     });

//     if (response.data) {
//       return response.data;
//     } else {
//       throw new Error('No data received from processUserInput');
//     }
//   } catch (error) {
//     console.error('Process user input API call failed:', error);
//     throw error;
//   }
// };
export const processUserInput = async (input, sessionId,isCampaignPrompt) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/process_user_input`, {
      input,
      sessionId,
      isCampaignPrompt:isCampaignPrompt?isCampaignPrompt:false
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from processUserInput');
    }
  } catch (error) {
    console.error('Process user input API call failed:', error);
    throw error;
  }
};

export const handleGeneralConversation = async (input, sessionId) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/handle_general_conversation`, {
      input,
      sessionId
    });

    if (response.data) {
      return response.data;
    } else {
      throw new Error('No data received from handleGeneralConversation');
    }
  } catch (error) {
    console.error('Handle general conversation API call failed:', error);
    throw error;
  }
};

export const processEmailRequest = async (contactData, topic,emailType, sessionId,userInput) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/emailProcessor`, {
      contactData,
      topic,
      emailType,
      sessionId,
      userInput
    });

    if (response.data && response.data.email_content) {
      return response.data;
    } else {
      throw new Error('No email content received from emailProcessor');
    }
  } catch (error) {
    console.error('Email processor API call failed:', error);
    throw error;
  }
};

// New function for gen AI prompt creation
export const createGenAIPrompt = async (task, aspect, sessionId,userInput) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/genAiPrompt`, {
      task,
      aspect,
      sessionId,
      userInput
    });
console.log("Response from genAiPrompt:", response);
    if (response.data && response.data.gen_ai_content) {
      return response.data;
    } else {
      throw new Error('No gen AI prompt received from genAiPrompt');
    }
  } catch (error) {
    console.error('Gen AI prompt creation API call failed:', error);
    throw error;
  }
};

export const scrapeLinkedInProfile = async (profileUrl, sessionCookie) => {
  console.log("Scraping LinkedIn profile:", profileUrl, sessionCookie);
  try {
    const response = await axios.post(`${API_BASE_URL}/api/scrape-linkedin-profile`, {
      profileUrl,
      sessionCookie
    });

    if (response) {
      console.log("LinkedIn profile data response:",response.data)
      return response.data;
    } else {
      throw new Error(response.data.error || 'Failed to scrape LinkedIn profile');
    }
  } catch (error) {
    console.error('LinkedIn profile scraping failed:', error);
    throw error;
  }
};

// Function to search companies
export const searchCompanies = async (params) => {
  const { keywords, location, page = 1, perPage = 10, userId } = params;
  console.log('API Search Params:', { keywords, location, page, perPage, userId });

  try {
    const response = await axios.post(`${API_BASE_URL}/api/companies`, {
      keywords,
      location,
      page,
      perPage,
      userId
    });

    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Failed to search companies');
    }
  } catch (error) {
    console.error('Company search API call failed:', error);
    throw error;
  }
};

export const WebScraper = async (query) => {
  try {
      const response = await fetch('https://videoresponse.onepgr.com:3001/webscraper', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              query: query
          })
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
  } catch (error) {
      console.error('Error calling the webscraper API:', error);
      throw error;
  }
}

export const scrapeCompanyEmployees = async (companyUrl, sessionCookie) => {
  console.log('Scraping company employees for:', companyUrl);
  
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const requestBody = {
      companyUrl,
      sessionCookie
    };

    console.log('Request body:', JSON.stringify(requestBody, null, 2));

    const response = await axios.post(
      'https://videoresponse.onepgr.com:3001/CompanyEmployeesScrap',
      requestBody,
      config
    );

    console.log('Response status:', response.status);
    console.log('Response data:', JSON.stringify(response.data, null, 2));

    // Check if response has data
    if (!response.data) {
      throw new Error('Empty response received from server');
    }

    // Check if employees array exists and is not empty
    if (!response.data.employees || !Array.isArray(response.data.employees)) {
      throw new Error('Invalid or missing employees data in response');
    }

    return {
      employees: response.data.employees,
      total: response.data.employees.length
    };

  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Server responded with error:', {
        status: error.response.status,
        data: error.response.data
      });
      throw new Error(`Server error: ${error.response.status} - ${JSON.stringify(error.response.data)}`);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
      throw new Error('No response received from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error setting up request:', error.message);
      throw error;
    }
  }
};

// Add this new function to your existing API file

export const searchJobs = async (jobTitle,location) => { 
  console.log(jobTitle,location)
  try {
    const response = await axios.post(`${API_BASE_URL}/api/jobs/search`, {
      jobTitle,
      location,
    });
   console.log('Job results:',response)
    if (response.data.success) {
      return response.data;
    } else {
      throw new Error(response.data.error || 'Failed to search jobs');
    }
  } catch (error) {
    console.error('Job search API call failed:', error);
    throw error;
  }
};

export const LinkedInCompanyScraper = async (profileUrl, sessionCookie) => {
  try {
      const response = await fetch('https://videoresponse.onepgr.com:3001/LinkedIncompanyurl', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              profileUrl: profileUrl,
              sessionCookie: sessionCookie
          })
      });
     
      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data)
      return data;
  } catch (error) {
      console.error('Error scraping LinkedIn company:', error);
      throw error;
  }
}