import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./SendEmail.scss";
import { FaTimes } from "react-icons/fa";
import {
  getSenderAppAccounts,
  inviteMultipleUsersToPage,
} from "../../../api/onepgrApi";
import { parseEmailContent } from "../../../api/babuApi";
import toasterService from "../../../services/toasterService";
import { useCombinedContext } from "../../context/userContext";
const SendEmail = ({
  toggleSendEmailModal,
  sendEmailAction,
  selectedMessageToSend,
}) => {
  const [selectedSenderId, setSelectedSenderId] = useState("");
  const [recipients, setRecipients] = useState([]);
  const [recipientInput, setRecipientInput] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [senderAppAccounts, setSenderAppAccounts] = useState([]);
  const { user, setUser } = useCombinedContext();
  const recipientsEmailsRef = useRef([]);

  useEffect(() => {
    (async () => {
      setEmailContent(selectedMessageToSend);
      await handleSenderAppAccounts();
    })();
  }, []);

  const handleSenderAppAccounts = async () => {
    try {
      const resp = await getSenderAppAccounts();
      console.log("resp--->", resp);
      if (resp.success === "0" && resp.response) {
        const nylas_app = resp.response.app_accounts_list;
        const senderAccountList = nylas_app.map((sender) => {
          let senderName;
          let senderEmail = sender.app_username;
          if (sender.account_details) {
            let accountDetails = JSON.parse(sender.account_details);
            senderName =
              accountDetails.firstName + " " + accountDetails.lastName;
          }
          return {
            senderId: sender.app_account_id,
            senderName: senderName,
            senderEmail: senderEmail,
          };
        });
        console.log("senderAccountList===>", senderAccountList);
        setSelectedSenderId(senderAccountList[0].senderId);
        setSenderAppAccounts(senderAccountList);
      }
    } catch (error) {
      console.error("Error fetching sender accounts:", error);
    }
  };

  const setEmailContent = (content) => {
    try {
      console.log("selectedMessageToSend------->", selectedMessageToSend);
      const checkForEmial = parseEmailContent(selectedMessageToSend);
      console.log("checkForEmial---->", checkForEmial);
      if (checkForEmial && (checkForEmial.subject || checkForEmial.body)) {
        if (checkForEmial.body) {
          checkForEmial.body = checkForEmial.body.replace(/\n/g, "<br>");
        }
        setSubject(checkForEmial.subject);
        setMessage(checkForEmial.body);
      } else {
        if (content && content.insights) {
         let msg = selectedMessageToSend.insights.join("<br>")
         setMessage(msg)
         setSubject("Insights")
        } else  if (content && content.creaetedVideoURl) {
           setMessage(content.creaetedVideoURl)
        } else  if (content && content.emailData) {
           setMessage(content.emailData.body?content.emailData.body.replace(/\n/g, "<br>"):content.emailData)
           setSubject(content.emailData.subject)
        } else setMessage(selectedMessageToSend.replace(/\n/g, "<br>"));
      }
    } catch (error) {
      console.error("Error parsing message content:", error);
      setMessage(content)
    }
  };

  const handleAddRecipient = () => {
    if (recipientInput.trim() && !recipients.includes(recipientInput.trim())) {
      setRecipients([...recipients, recipientInput.trim()]);
      recipientsEmailsRef.current.push(recipientInput.trim());
      setRecipientInput("");
    }
  };

  const handleRemoveRecipient = (email) => {
    setRecipients(recipients.filter((recipient) => recipient !== email));
  };

  const handleSubmit = () => {
    const warningMsgs = [];

    // Check for missing fields
    console.log(
      " recipientsEmailsRef.current---->",
      recipientsEmailsRef.current
    );
    if (!selectedSenderId) warningMsgs.push("Please select a sender account");
    if (
      !recipientsEmailsRef.current ||
      recipientsEmailsRef.current.length === 0
    )
      warningMsgs.push("Enter email addresses");
    if (!subject) warningMsgs.push("Message subject is empty");
    if (!message) warningMsgs.push("Message body is empty");

    // Show warnings for missing fields
    if (warningMsgs.length > 0) {
      warningMsgs.forEach((msg) => {
        toasterService.warning(msg);
      });
      return;
    }

    // All fields are present; proceed with email submission
    const emailData = {
      selectedSenderId,
      recipients,
      subject,
      message,
    };

    try {
      const invitationData = getInvitationData();
      const selectedSenderAccount = senderAppAccounts.find((account) => {
        return account.senderId === selectedSenderId;
      });
      invitationData.id = "155229";
      invitationData.requestor_name = selectedSenderAccount.senderName
        ? selectedSenderAccount.senderName
        : selectedSenderAccount.senderEmail;
      invitationData.requestor_email = selectedSenderAccount.senderEmail;
      invitationData["app_account_id"] = selectedSenderAccount.senderId;
      // invitationData.user_orgid = this.userService.getCurrentUserOrgId();
      invitationData.message_subject = subject;
      invitationData.msg = message;
      // invitationData.object_id = this.object_id;
      // invitationData.object_type = this.object_type;
      invitationData.sender_user_id = user.userId;
      invitationData.create_user = 0;
      invitationData.activity_id = Math.floor(Math.random() * 90000) + 10000;
      validateEmail(invitationData);
    } catch (error) {
      console.log("Error sending email:", error);
    }
  };

  const getInvitationData = () => {
    return {
      clientname: "web_meet",
      clientappid: "22",
      clientappkey: "33",
      onepgr_apicall: 1,
      xhr_flag: 1,
      create_user: 1,
    };
  };

  const validateEmail = async (invitationData) => {
    try {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let validEmail = true;
      for (let i = 0; i < recipientsEmailsRef.current.length; i++) {
        if (re.test(String(recipientsEmailsRef.current[i]).toLowerCase())) {
          continue;
        } else {
          validEmail = false;
          break;
        }
      }
      if (validEmail) {
        invitationData.account_email = recipientsEmailsRef.current.toString();

        const resp = await inviteMultipleUsersToPage(invitationData);
        console.log("resp---------->", resp);
        toasterService.success("Email sent successfully");
      } else {
        toasterService.error("Enter a valid email address");
      }
    } catch (error) {
      console.log("Error sending email:", error);
    } finally {
      toggleSendEmailModal(false);
    }
  };

  const handleBlur = () => {
    handleAddRecipient();
  };

  return (
    <div className="send-email-overlay">
      <div className="send-email-modal">
        <div className="send-email-header">
          <h2>Send Email</h2>
          <button
            className="close-btn"
            onClick={() => toggleSendEmailModal(false)}
          >
            <FaTimes />
          </button>
        </div>

        <div className="send-email-body">
          <div className="send-email-form-groups">
            <label>Select Sender</label>
            <select
              value={selectedSenderId}
              onChange={(e) => setSelectedSenderId(e.target.value)}
              className="select-sender-dropdown"
            >
              {senderAppAccounts.map((account, index) => (
                <option key={index} value={account.senderId}>
                  {account.senderName
                    ? account.senderName
                    : account.senderEmail}
                </option>
              ))}
            </select>
          </div>

          <div className="send-email-form-groups">
            <label>Recipients</label>
            <div className="recipients-container">
              <div className="recipients-container-child">
                {recipients.map((email, index) => (
                  <span key={index} className="chip">
                    {email}
                    <button
                      className="chip-close-btn"
                      onClick={() => handleRemoveRecipient(email)}
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
              <input
                type="text"
                placeholder="Enter email address(es)..."
                value={recipientInput}
                onChange={(e) => setRecipientInput(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && handleAddRecipient()}
                onBlur={handleBlur}
              />
            </div>
          </div>

          <div className="send-email-form-groups">
            <label>Subject</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="text-input"
              placeholder="Email subject"
            />
          </div>

          <div className="send-email-form-groups quill-send-message-group">
            <label>Message</label>
            <ReactQuill
              value={message}
              onChange={setMessage}
              theme="snow"
              className="quill-editor"
            />
          </div>
        </div>

        <div className="send-email-footer">
          <button
            className="cancel-btn"
            onClick={() => toggleSendEmailModal(false)}
          >
            Cancel
          </button>
          <button className="send-btn" onClick={handleSubmit}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendEmail;