import React from 'react';
import '../styles/runwayVideoCreator.css'; // Import the CSS file

const RunwayVideoCreator = ({ data }) => {
//   if (!data || !data.creaetedVideoURl) {
//     console.log("Video URL not available:", data);
//     return <p className="no-video">No video available</p>;
//   }


  console.log("Playing video from URL:", data);

  return (
    <div className="video-container">
          <p className="video-note">Video generated successfully!</p>
      <div className="video-wrapper">
        <video
          controls
          autoPlay
          className="video-player"
          preload="metadata"
          crossOrigin="anonymous" // Allows for CORS issues to be addressed
        >
            
          <source src={data} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default RunwayVideoCreator;