import React from "react";
import "../styles/ipEnrichment.css";

const IpEnrichment = ({ data }) => {
  if (!data) {
    return <div className="p-4 text-red-500">No data available</div>;
  }

  const { ip, company, person } = data;

  // Format details and location for the company
  const formatDetails = (obj) => {
    if (!obj) return <span>N/A</span>;
    return (
      <ul className="details-list">
        <li><strong>Name:</strong> {obj.name || "N/A"}</li>
        <li><strong>Display Name:</strong> {obj.display_name || "N/A"}</li>
        <li><strong>Website:</strong> {obj.website || "N/A"}</li>
        <li><strong>Industry:</strong> {obj.industry || "N/A"}</li>
        <li><strong>Employee Count:</strong> {obj.employee_count || "N/A"}</li>
        <li><strong>Size:</strong> {obj.size || "N/A"}</li>
        <li><strong>Revenue:</strong> {obj.inferred_revenue || "N/A"}</li>
      </ul>
    );
  };

  const formatLocation = (obj) => {
    if (!obj) return <span>N/A</span>;
    return (
      <ul className="location-list">
        <li><strong>Name:</strong> {obj.name || "N/A"}</li>
        <li><strong>Locality:</strong> {obj.locality || "N/A"}</li>
        <li><strong>Region:</strong> {obj.region || "N/A"}</li>
        <li><strong>Metro:</strong> {obj.metro || "N/A"}</li>
        <li><strong>Country:</strong> {obj.country || "N/A"}</li>
        <li><strong>Continent:</strong> {obj.continent || "N/A"}</li>
        <li><strong>Street Address:</strong> {obj.street_address || "N/A"}</li>
        <li><strong>Address Line 2:</strong> {obj.address_line_2 || "N/A"}</li>
        <li><strong>Postal Code:</strong> {obj.postal_code || "N/A"}</li>
        <li><strong>Geo:</strong> {obj.geo || "N/A"}</li>
      </ul>
    );
  };

  const rows = [
    {
      name: "IP Address",
      details: ip?.address || "N/A",
      location: formatLocation(ip?.location),
    },
    {
      name: "Company",
      details: formatDetails(company),
      location: formatLocation(company?.location),
    },
    {
      name: "Person",
      details: person?.name || "N/A",
      location: "N/A",
    },
  ];

  return (
    <div className="people-table-container">
      <h2 className="tableName">Ip Enriched Details:</h2>
      <table className="people-table">
        <thead>
          <tr>
            <th style={{ fontWeight: "bold" }}> Name</th>
            <th style={{ fontWeight: "bold" }}>Details</th>
            <th style={{ fontWeight: "bold" }}>Location</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td style={{ fontWeight: "bold" }}>{row.name}</td>
              <td style={{ fontWeight: "bold" }}>{row.details}</td>
              <td style={{ fontWeight: "bold" }}>{row.location}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IpEnrichment;