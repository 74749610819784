import React from 'react';
import '../styles/jobSearchData.css';

const JobTable = ({ data }) => {
  if (!data || data.length === 0) {
    return <div>No jobs found</div>;
  }

  return (
    <div className="job-search-wrapper">
      <div className="job-header">
        <p>Job Search Results ({data.length} jobs found)</p>
      </div>
      
      <div className="job-table-wrapper">
        <table className="job-results-table">
          <thead>
            <tr>
              <th>Source</th>
              <th>Company</th>
              <th>Title</th>
              <th>Location</th>
              <th>View Job</th>
            </tr>
          </thead>
          <tbody>
            {data.map((job, index) => (
              <tr key={index}>
                <td>
                  <div className="job-cell-content">
                    <p>{job.source}</p>
                  </div>
                </td>
                <td>
                  <div className="job-cell-content">
                    <p>{job.company}</p>
                  </div>
                </td>
                <td>
                  <div className="job-cell-content">
                    <p>{job.title}</p>
                  </div>
                </td>
                <td>
                  <div className="job-cell-content">
                    <p>{job.location}</p>
                  </div>
                </td>
                <td>
                  <a 
                    href={job.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="job-link-button"
                  >
                    View Details
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobTable;