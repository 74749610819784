/* global Twilio */
import axios from "axios";
import EventEmitter from "eventemitter3";

const twilioEventEmitter = new EventEmitter();

let twilioConnectionObject = null;
let twilioIncomingConnectionObject = null;
let twilioFromNumber = "";
let isValidUser = false;
const meetSessionApi = "https://meet.onepgr.com/session";
function validateUser(userData) {
//   const userData = userService.getUserData();
  if (userData) {
    const purchasedNumber = userData.purch_phone_no_2
      ? userData.purch_phone_no_2
      : "";
    if (purchasedNumber) {
      twilioFromNumber = purchasedNumber.startsWith("+")
        ? purchasedNumber
        : `+${purchasedNumber}`;
      isValidUser = true;
    } else {
      isValidUser = false;
    }
  }
  console.log(
    twilioConnectionObject,
    twilioIncomingConnectionObject,
    twilioFromNumber
  );
  return isValidUser;
}

async function twilioCallSetup() {
  try {
    const res = await getTwilioToken();
    if (res) {
      twilioEventSetup(res.data.token);
    }
  } catch (error) {
    console.error("Error setting up Twilio:", error);
  }
}

async function getTwilioToken() {
  const headers = {
    Accept: "application/json; charset=utf-8",
  };
  try {
    return await axios.get(`${meetSessionApi}/twilio-token`, {
      headers: headers,
    });
  } catch (error) {
    console.error("Error getting Twilio token:", error);
    throw error;
  }
}

function twilioEventSetup(token) {
  console.log("Got Token ====>");
  Twilio.Device.setup(token, {
    enableRingingState: true,
    closeProtection: true,
  });

  Twilio.Device.on("connect", (conn) => {
    twilioConnectionObject = conn; // Assign the connection object
    const data = {
      status: "calling",
      connData: conn,
    };
    twilioEventEmitter.emit("calling", data);
    console.log("Successfully Started Dialing");
  });

  Twilio.Device.on("incoming", (conn) => {
    twilioIncomingConnectionObject = conn;
    console.log("Twilio incoming");
  });

  Twilio.Device.on("ready", () => {
    console.log("Twilio client is ready for making calls.");
  });

  Twilio.Device.on("cancel", () => {
    console.log("Call canceled");
  });

  Twilio.Device.on("disconnect", (conn) => {
    console.log("Call ended");
    const data = {
      status: "disconnect",
      connData: conn,
    };
    twilioEventEmitter.emit("disconnect", data);
  });

  Twilio.Device.on("error", (error) => {
    Twilio.Device.disconnectAll();
    console.log("Error: " , error);
  });

  Twilio.Device.on("offline", () => {
    console.log("Twilio.Device is now offline for connections");
  });
}

function twilioCall(toNumber, outboundNumber) {
  const params = {
    PhoneNumber: `[${toNumber}]`,
    FromPhoneNumber: outboundNumber ? formatOutboundNumber(outboundNumber) : twilioFromNumber,
    From: "TestCaller",
    Caller: "TestCaller",
    Name: "testUser",
  };
  twilioConnectionObject = Twilio.Device.connect(params);
}

function formatOutboundNumber(outboundNumber) {
  return outboundNumber.startsWith("+")
    ? outboundNumber
    : `+${outboundNumber}`;
}

function startRecording(agentOnly) {
  const callSid = twilioConnectionObject.parameters.CallSid;
  return axios.post(`${meetSessionApi}/twilio-call-recording`, {
    call_sid: callSid,
    agentOnly: agentOnly ? true : false,
  });
}

function updateRecordingStatus(r_sid, status) {
    let data = {
      r_sid: r_sid,
      call_sid:  twilioConnectionObject.parameters.CallSid,
      status: status
    };
    return axios.post(`${meetSessionApi}/updateRecordingStatus`, data);
  }


function hangup() {
    Twilio.Device.disconnectAll();
  }
function twilioCallMute(muteStatus) {
    try{
    console.log("muteStatus",muteStatus);
    Twilio.Device.activeConnection().mute(muteStatus);
    }
    catch (err) {
        console.log("muteStatus",err)
    }
  }

export const twilioService = {
  twilioCallSetup,
  twilioEventEmitter,
  twilioCall,
  validateUser,
  startRecording,
  hangup,
  twilioCallMute,
  updateRecordingStatus
};