import React, { useState, useMemo } from 'react';

const extractTitleAndCompany = (job) => {
  // Default company name for all employees
  const defaultCompany = "SaaS Labs";
  
  if (!job) return { title: 'N/A', company: defaultCompany };
  
  // Split by @ or "at" and clean up
  const parts = job.split(/(?:@|at)/i);
  if (parts.length > 1) {
    return {
      title: parts[0].trim(),
      company: defaultCompany
    };
  }
  
  // If no @ or "at" found, just use the whole string as title
  return {
    title: job.replace(/\|.*$/, '').trim(),
    company: defaultCompany
  };
};

const EmployeeTable = ({ data , onEnrichContacts}) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filter, setFilter] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  // Format data and filter out entries with errors
  const formattedData = useMemo(() => {
    return data
      .filter(employee => !employee.error)
      .map(employee => {
        const { title, company } = extractTitleAndCompany(employee.job);
        return {
          ...employee,
          title,
          company
        };
      });
  }, [data]);

  const handleCheckboxChange = (employee) => {
    setSelectedEmployees(prev =>
      prev.some(e => e._id === employee._id)
        ? prev.filter(e => e._id !== employee._id)
        : [...prev, employee]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedEmployees(filteredEmployees);
    } else {
      setSelectedEmployees([]);
    }
  };

  const sortedEmployees = useMemo(() => {
    let sortableEmployees = [...formattedData];
    if (sortConfig.key !== null) {
      sortableEmployees.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableEmployees;
  }, [formattedData, sortConfig]);

  const filteredEmployees = sortedEmployees.filter(employee => {
    const searchStr = filter.toLowerCase();
    return (
      employee.name.toLowerCase().includes(searchStr) ||
      employee.title.toLowerCase().includes(searchStr) ||
      employee.company.toLowerCase().includes(searchStr) ||
      employee.location.toLowerCase().includes(searchStr)
    );
  });

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleEnrichClick = () => {
    if (selectedEmployees.length > 0) {
      onEnrichContacts(selectedEmployees);
    }
  };  

  return (
    <div className="people-table-container">
      <table className="people-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedEmployees.length === filteredEmployees.length && filteredEmployees.length > 0}
                className="cursor-pointer"
              />
            </th>
            <th className="tableName">
              <p onClick={() => requestSort('name')}>
                Name {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
              </p>
            </th>
            <th onClick={() => requestSort('title')}>
              Title {sortConfig.key === 'title' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('company')}>
              Company {sortConfig.key === 'company' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th onClick={() => requestSort('location')}>
              Location {sortConfig.key === 'location' && (sortConfig.direction === 'ascending' ? '▲' : '▼')}
            </th>
            <th>LinkedIn</th>
          </tr>
        </thead>
        <tbody>
          {filteredEmployees.map((employee) => (
            <tr key={employee._id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedEmployees.some(e => e._id === employee._id)}
                  onChange={() => handleCheckboxChange(employee)}
                />
              </td>
              <td className="tableName-value"><p>{employee.name}</p></td>
              <td>{employee.title}</td>
              <td>{employee.company}</td>
              <td>{employee.location}</td>
              <td>
                {employee.profileUrl ? (
                  <a
                    href={employee.profileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Profile
                  </a>
                ) : 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="table-footer">
        <p>Total employees found: {filteredEmployees.length}</p>
        <button
         onClick={handleEnrichClick}
          disabled={selectedEmployees.length === 0}
          className={`${selectedEmployees.length === 0 ? 'opacity-50' : 'enrich-selected-btn'}`}
        >
          Enrich Selected Employees ({selectedEmployees.length})
        </button>
      </div>
    </div>
  );
};

export default EmployeeTable;