import { Bounce, toast, ToastOptions } from "react-toastify";

const defaultOptions = {
  position: "top-right",
  autoClose: 3500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
  transition: Bounce,
};

class ToasterService {
   defaultOptions;
  constructor() {
    this.defaultOptions = defaultOptions;
  }

   success(message) {
    toast.success(message, this.defaultOptions);
  }

   error(message) {
    toast.error(message, this.defaultOptions);
  }

   info(message) {
    toast.info(message, this.defaultOptions);
  }

   warning(message) {
    toast.warning(message, this.defaultOptions);
  }
}

const toasterService = new ToasterService();
export default toasterService;